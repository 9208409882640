@import "../../../styles/circle.scss";

.key-value-formula {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 10px;
    width: 100%;

    >.empty {
        color: #818094;
    }

    >.key-value-formula-item {
        display: flex;
        align-items: center;
        position: relative;

        >.variable {
            padding: 5px 15px;
            background: rgba(84, 99, 255, 0.2);
            border-radius: 20px;
        }

        >.account {
            padding: 5px 15px;
            background: rgba(76, 175, 80, 0.2);
            border-radius: 20px;
        }

        >.account-span {
            padding: 5px 15px;
            background: #FFD1FE;
            border-radius: 20px;
        }

        >.number {
            padding: 5px 15px;
            background: rgba(255, 213, 28, 0.2);
            border-radius: 20px;
        }

        >.arithmetic {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #030229;
            @include circle(30px);
            color: #fff;
            font-size: 14px;
        }
    }

    .line {
        font-size: 14px;
        animation: blink 1s steps(1) infinite;
    }

    >.hidden-item {
        width: 100%;
        height: 20px;
        flex: 1;
        cursor: text;
    }
}

@keyframes blink {
    0% {
        color: transparent;
    }

    50% {
        color: #000D33;
    }
}