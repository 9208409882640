.balance-report-subrow {
    >td {
        padding: 10px;
        text-align: end;
        border-bottom: var(--border-primary);

        &.title {
            text-align: start;
        }
    }
}