.liquidity-report-row-title {
    background: #EFEFFF;

    >td {
        padding: 7px 10px;
    }
}

.liquidity-report-row {
    >td {
        padding: 10px 15px;
        border-left: var(--border-primary);
        text-align: end;

        &:first-child {
            border-left: none;
        }

        >input[type="tel"] {
            outline: none;
            background: #ffffff;
            text-align: end;
            padding: 5px 0;
            padding-right: 3px;
            width: 80px;
            border-radius: 40px;
            font-weight: 400;
            font-size: 12px;
            border: none;
            border: 1px solid #efefff;
            cursor: text;
        }
    }
}