@import "../../styles/variables.scss";

.onbording-layout {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-template-areas:
        "content background"
    ;
    gap: 40px;
    background-color: #fafafb;
    max-width: 1920px;

    >.content {
        grid-area: content;
        padding: 30px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: var(--border-primary);
        border-radius: 10px;
        width: 450px;
        min-height: 600px;
    }
}

@media screen and (max-width: $small-breakpoint) {
    .onbording-layout {
        display: flex;
        justify-content: center;

        .content {
            width: 100%;
            padding: 20px;
        }

        .space-grotesk {
            font-size: 25px;
        }
    }
}