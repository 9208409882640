@import "./variables.scss";
@import url("https://use.typekit.net/lad0wjt.css");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

body {
  min-width: 1300px;
  font-family: "aktiv-grotesk", sans-serif;
  background: #F6F9FC;
  color: #000D33;
}

.space-grotesk {
  font-family: "Space Grotesk", sans-serif;
}

a {
  text-decoration: none;
}

.nimya-custom-alert {
  background: unset;
  z-index: 100;
}

.fa-google {
  background: conic-gradient(from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

* {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 10px;
  }

  /*7e*/

  ::-webkit-scrollbar-thumb:hover {
    background: #000D33b0;
  }
}

@media screen and (max-width: $small-breakpoint) {
  body {
    min-width: 0;
  }
}