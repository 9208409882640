@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.dev-banner {
    font-family: 'Press Start 2P',
        cursive;
    text-shadow: 2px 2px 4px #000000;
    font-size: 20px;

    /* Chrome, Safari, Opera */
    -webkit-animation: rainbow 10s infinite;

    /* Internet Explorer */
    -ms-animation: rainbow 10s infinite;

    /* Standar Syntax */
    animation: rainbow 10s infinite;

    @keyframes rainbow {
        0% {
            color: orange;
        }

        10% {
            color: purple;
        }

        20% {
            color: red;
        }

        30% {
            color: CadetBlue;
        }

        40% {
            color: yellow;
        }

        50% {
            color: coral;
        }

        60% {
            color: green;
        }

        70% {
            color: cyan;
        }

        80% {
            color: DeepPink;
        }

        90% {
            color: DodgerBlue;
        }

        100% {
            color: orange;
        }
    }
}