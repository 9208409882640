.settings {
    >.controls {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 20px;

        >.add-company {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: auto;

            >span {
                color: #818094;
            }
        }

        >.consult-dropdown {
            margin-right: auto;
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}