.waves-effect {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}
.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}
.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}
.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}
.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}
.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}
.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}
.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}
.waves-effect input[type="button"],
.waves-effect input[type="reset"],
.waves-effect input[type="submit"] {
  border: 0;
  font-style: normal;
  font-size: inherit;
  text-transform: inherit;
  background: none;
}
.waves-effect img {
  position: relative;
  z-index: -1;
}
.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}
.waves-circle {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}
.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}
.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}
.waves-block {
  display: block;
}
.waves-effect .waves-pple {
  z-index: -1;
}
.waves-float {
  -webkit-mask-image: none;
  -webkit-box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.waves-float:active {
  -webkit-box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
