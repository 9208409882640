$small-breakpoint: 768px;

:root {
    /*colors*/
    --primary: #374263;

    --background: #FAFAFB;
    --card-bg: #fff;

    --text-primary: #000D33;
    --text-secondary: #818094;
    --text-light: #fff;

    --text-income: #5B93FF;
    --text-expenses: #EF5FFC;
    --text-result: #00B448;

    /*border*/
    --border-primary: 1px solid #EFEFFF;
}

/*font utility classes*/
.text-xs-m {
    font-size: 10px;
    font-weight: 400;
}

.text-s-r {
    font-size: 12px;
    font-weight: 400;
}

.text-s-m {
    font-size: 12px;
    font-weight: 500;
}

.text-m-r {
    font-size: 13px;
    font-weight: 400;
}

.text-m-m {
    font-size: 13px;
    font-weight: 400;
}

.text-l-m {
    font-size: 18px;
    font-weight: 700;
}