@import '../../styles/circle.scss';

.accounting-firm-customers {

    >.top {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;

        >.customer-mode-checkbox {
            position: relative;
        }
    }

    >table {
        width: 100%;
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;

        >thead {
            >tr {
                >th {
                    text-align: start;
                    padding: 20px;
                    border-bottom: var(--border-primary);
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    padding: 12.5px 20px;
                    border-bottom: var(--border-primary);
                }

                &:last-child {
                    >td {
                        border-bottom: none;
                    }
                }
            }
        }

        &.inactive-customers {
            >tbody {
                >tr {
                    >td {
                        >.confirm-button {
                            @include circle(30px);
                            border: 1px solid #31BD00;
                            background: #f5fcf2;
                            color: #31BD00;
                            margin-right: 10px;
                        }

                        >.deny-button {
                            @include circle(30px);
                            border: 1px solid #FF0000;
                            background: #fff2f2;
                            color: #FF0000;
                        }
                    }
                }
            }
        }
    }

    >.no-waiting-customers {
        font-size: 13px;
        font-weight: 600;
        opacity: 0.4;
        text-align: center;
    }
}