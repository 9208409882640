.backend-is-down {
    &--title {
        font-weight: 500;
        font-size: 40px;
        margin: 0;
        padding-bottom: 20px;
        margin-bottom: 30px;
        width: 100%;
        border-bottom: 1px solid #EFEFFF;
    }

    &--sub-title {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 30px;
    }

    &--buttons {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }
}