.custom-alert-popup {
    >.header {
        background: #374263;
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 10px 15px;

        >span {
            color: #FFF;
            margin-right: auto;
        }
    }

    >.content {
        display: flex;

        >.steps {
            width: 100%;
            display: flex;
            flex-direction: column;

            >.step {
                border-bottom: 1px solid #EFEFFF;
                padding: 20px;

                >.title {
                    color: #818094;
                    margin-bottom: 10px;

                    >span {
                        color: #000D33;
                    }
                }

                >.value {

                    >textarea {
                        width: 100% !important;
                        height: 75px !important;
                        outline: none;
                        border: 1px solid #EFEFFF;
                        resize: none;
                        border-radius: 10px;
                        background: #FFFFFF;
                        padding: 10px;

                        &::placeholder {
                            color: #818094;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}