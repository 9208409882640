.voucher-row {
    border-bottom: 1px solid #efefff;

    &.removed {
        td {
            text-decoration: underline;
            text-decoration-thickness: 1.1px;
            text-underline-offset: -0.35rem;
            text-decoration-skip-ink: none;
        }
    }

    td {
        padding: 15px 20px;
        min-width: 20px;
        position: relative;
        font-size: 13px;
        text-align: end;
    }

    >.account {
        text-align: start !important;
    }
}