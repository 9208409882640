.balance-report-row {
    cursor: pointer;

    >td {
        padding: 10px;
        text-align: end;
        border-bottom: var(--border-primary);

        &.title {
            text-align: start;

            >div {
                >i {
                    margin-right: 25px;
                }
            }
        }
    }
}