@import '../../styles/circle.scss';

.company-selector-wrapper {
    position: relative;

    >.company-click {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        padding-right: 10px;

        >.company-button {
            @include circle(40px);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #030229;
            color: #fff;
        }

        &:hover {
            >span {
                text-decoration: underline;
            }
        }
    }

    >.company-selector {
        position: absolute;
        top: 60px;
        left: -11px;
        width: 390px;
        background: var(--card-bg);
        border-radius: 10px;
        z-index: 10000;
    }
}