.account-row {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #818094;
    cursor: pointer;
    padding: 10px 15px;

    &:hover {
        background: #EFEFFF;
    }

    &.active {
        color: #030229;
    }

    >.account-number {}

    >.account-name {
        margin-right: auto;
    }
}