.extended-filtering-wrapper {
    position: relative;

    >.button-wrapper {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    >.extended-filtering {
        position: absolute;
        top: 40px;
        right: 0;
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        width: 400px;
        z-index: 100;

        >.section {


            >.title {
                padding: 15px 20px 5px 20px;
                color: #818094;
            }

            >.row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                cursor: pointer;

                &:hover {
                    background: #efefff;
                }
            }
        }
    }
}