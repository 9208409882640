.balance-report-section {
    border-radius: 10px;
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    background: var(--card-bg);
    border: var(--border-primary);

    >thead {
        background: #374263;
        color: #fff;

        >tr {
            >th {
                text-align: end;
                width: 200px;
                padding: 10px;

                &.title {
                    text-align: start;
                }
            }
        }
    }
}