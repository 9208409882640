.variable-list {
    width: 300px;
    min-height: 650px;
    max-height: 650px;
    overflow: auto;
    border-right: 1px solid #efefff;

    >.section {
        border-bottom: 1px solid #efefff;

        >.title {
            color: #818094;
            padding: 10px 15px;
        }
    }
}