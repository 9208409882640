.group-companies {
    >.title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: var(--border-primary);
        padding: 20px;
    }

    >.new-company {
        display: flex;
        gap: 20px;
        border-bottom: var(--border-primary);
        padding: 20px;
    }

    >.filter {
        display: flex;
        border-bottom: var(--border-primary);
        padding: 20px;
    }

    >.companies {
        >.group-company {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: var(--border-primary);

            >.delete {
                color: #ff0000;
                font-size: 12px;
                font-weight: 400;
                background: #FFFFFF;
                border: var(--border-primary);
                border-radius: 2px;
                padding: 2px 5px;
            }
        }
    }

    >.group-companies-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
}