.forecast {
    display: flex;
    flex-direction: column;
    gap: 30px;

    >.top {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }

    >.reload-wrapper {
        display: flex;
        justify-content: center;
    }
}