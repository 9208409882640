.no-companies {
    &--title {
        font-weight: 500;
        font-size: 40px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        width: 100%;
        border-bottom: 1px solid #EFEFFF;
    }

    &--description {
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EFEFFF;

        p {
            span {
                font-weight: 700;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            font-size: 16px;
            font-weight: 500;
            margin: 20px 0 0 0;

            li {
                margin: 5px 0;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    background: #D9D9D9;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin-right: 10px;
                    border-radius: 50%;
                }
            }
        }
    }

    &--sources {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;

        .no-comp-source {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 15px 0;
            border-bottom: 1px solid #EFEFFF;
            font-weight: 500;
            font-size: 14px;
        }
    }

    &--logout {
        margin-top: 40px;
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        i {
            color: #5463ff;
        }
    }
}