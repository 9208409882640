.alert-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    >.top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span {

            >i {
                margin-right: 10px;
            }
        }
    }
}