.down-for-maintenance {
    background: #000d33;
    height: 100vh;
    width: 100vw;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    >.maintenance-message {
        >i {
            font-size: 70px;
            margin-bottom: 20px;
            --fa-primary-color: #f87431;
            --fa-secondary-color: #fff;
            --fa-secondary-opacity: 1;
        }

        >p>a {
            color: #fff;
            text-decoration: underline;
        }
    }
}