.admin-users-filter {
    background: #fff;
    border: 1px solid #efefff;
    border-radius: 15px;

    >.admin-users-group {
        border-bottom: 1px solid #efefff;
        width: 100%;
        display: flex;

        >.group-input {
            width: 25%;
            border-right: 1px solid #efefff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            &:last-child {
                border-right: none;
            }
        }
    }

    >.admin-users-buttons {
        display: flex;
        gap: 20px;
        padding: 20px;
    }
}