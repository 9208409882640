.chart-wrapper {
    border-radius: 10px;
    background: var(--card-bg);
    overflow: hidden;

    >.top {
        background: #374263;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        >.legend-wrapper {
            display: flex;
            gap: 20px;
        }
    }

    >.content {
        padding: 20px;
        height: 100%;
    }
}