.result-report-cell {
    border-right: var(--border-primary);

    &:last-child {
        border-right: none;
    }

    >.content {
        padding: 7px;
        text-align: end;

        >p {
            color: #000D33;

            &.compare-value {
                color: #818094;
            }
        }
    }
}