.company-settings {
    display: flex;
    gap: 30px;

    >.list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    >.profile-settings {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 30px;

        >.card {
            background: var(--card-bg);
            border: var(--border-primary);
            border-radius: 10px;
            width: 400px;

            >form {
                padding: 30px 20px;
                border-top: var(--border-primary);
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

            }
        }

        >.logout {
            cursor: pointer;

            >i {
                margin-left: 15px;
            }
        }
    }
}