.voucher-filter {
    background: #fff;
    border: 1px solid #efefff;
    border-radius: 15px;

    &--group {
        border-bottom: 1px solid #efefff;
        width: 100%;
        display: flex;

        &__input {
            width: 25%;
            border-right: 1px solid #efefff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            &:last-child {
                border-right: none;
            }

            .dates {
                width: 100%;

                .title {
                    width: 100%;
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }
            }
        }
    }



    &--buttons {
        display: flex;
        gap: 20px;
        padding: 20px;

        &__more-options {
            cursor: pointer;
            font-size: 14px;
            margin-left: auto;
            text-decoration: underline;
        }
    }
}