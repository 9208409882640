.payment-sheet-input {
    padding: 15px;
    border-left: var(--border-primary);
    text-align: end;

    &:first-child {
        border-left: none;
    }


    >input {
        outline: none;
        background: #ffffff;
        text-align: end;
        padding: 0px 0;
        width: 80px;
        border-radius: 40px;
        border: none;
        cursor: pointer;
        padding-right: 3px;

        &:focus {
            border: var(--border-primary);
            cursor: text;
        }
    }
}