.payment-sheet {
    >.top {
        >.title {}

        margin-bottom: 20px;
    }

    >table {
        border-radius: 10px;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
        background: var(--card-bg);
        border: var(--border-primary);

        >thead {
            background: #374263;
            color: #fff;

            >tr {
                >th {
                    min-width: 100px;
                    text-align: end;
                    padding: 7px 10px;
                    border-left: 1px solid #68677F;

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }
    }
}