.settings-row {
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 10px;

    >.row {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        cursor: pointer;

        >.row-section {
            display: flex;
            align-items: center;
            gap: 5px;

            >.redo-setup {
                color: red;
            }
        }

    }



    >.company-settings {
        display: flex;
        flex-direction: column;
        border-top: var(--border-primary);

        >table {
            width: 100%;

            >thead {
                >tr {
                    >th {
                        text-align: start;
                        padding: 20px;

                        &.text-head {
                            width: 35%;
                        }
                    }
                }
            }

            >tbody {
                >tr {
                    >td {
                        padding: 12.5px 20px;

                        &.admin {
                            >.admin-actions {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                            }
                        }

                        >.settings-licenses {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            width: fit-content;
                        }

                        &.company-settings-cell {
                            padding: 0;
                            padding-top: 20px;
                            padding-bottom: 15px;
                            border-top: var(--border-primary);

                            >.company-settings-display {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;

                                >.title {
                                    padding: 0 20px;
                                }

                                >.row {
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;
                                    gap: 5px;
                                    padding: 0 20px;

                                    >.title {
                                        width: 100%;
                                        color: #A6A6AF;
                                    }

                                    >.value {
                                        margin-right: auto;
                                    }

                                    >i {
                                        cursor: pointer;
                                        color: #818094;
                                        font-size: 12px;
                                    }
                                }

                                >.divider {
                                    border-bottom: var(--border-primary);
                                }
                            }
                        }
                    }

                    &.form-row {
                        >td {
                            padding: 10px 20px;
                            border-top: var(--border-primary);
                        }
                    }
                }
            }
        }
    }
}