.accounting-firm-profile {
    display: flex;
    gap: 30px;

    >form {
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        width: 100%;

        >.top {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        >.profile-title {
            padding: 12.5px 20px;
            border-top: var(--border-primary);
        }

        >.profile-section {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 12.5px 20px;

            >span {
                margin-right: auto;
            }
        }

        >.profile-save-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 65px;
            margin-bottom: 30px;
            padding-right: 20px;
        }
    }

    >.profile-logo {
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        min-width: 400px;
        height: 450px;

        >.top {
            padding: 20px;
            border-bottom: var(--border-primary);
        }
    }
}