.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000D33;

  &.short {
    width: 150px;
  }

  .input {
    outline: none;
    padding: 10px 15px;
    border-radius: 40px;
    background: #fff;
    border: 1px solid #A6A6AF;
    color: #000D33;
    width: 100%;

    &.short {
      padding: 0 10px;
      height: 32px;
    }

    &.transparent {
      background: transparent;
      border: 1px solid #A6A6AF;
      color: #fff;

      &::placeholder {
        color: #A6A6AF;
        opacity: 1;
      }
    }
  }
}