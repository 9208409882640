.dots-modal-wrapper {
    position: relative;

    >.dots-modal {
        position: absolute;
        top: 20px;
        right: 0;
        width: 400px;
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        z-index: 1000;

        &.fixed-menu {
            position: fixed;
        }

        >.title {
            color: #818094;
            padding: 20px 20px 5px 20px;
        }

        >.content-row {
            padding: 11.5px 20px;
            cursor: pointer;

            &:hover {
                background: #efefff;
            }
        }
    }
}