.key-values-cell {
    text-align: end;
    padding: 10px;
    border-left: var(--border-primary);

    >div {
        >.value {}

        >.compare {
            color: #818094;
        }
    }
}