.budget-bulk-popup-top {
    >p {
        color: #A6A6AF;
    }
}

.budget-bulk {
    >.budget-bulk-rows {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
    }

    .budget-bulk-row {
        display: flex;
        align-items: center;

        &.whole-row {
            padding: 15px;
            border-bottom: var(--border-primary);
        }

        >.values {
            margin-left: auto;
            margin-right: 20px;
            color: #A6A6AF;
            display: flex;
            align-items: center;
            gap: 3px;

            >.changed-value {
                color: #000D33;
            }
        }

        .procent-input {
            text-align: center;
        }

        >.procent {
            margin-left: 5px
        }
    }

    >.bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-top: var(--border-primary);
    }
}