.overview-row {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: var(--border-primary);
    margin-bottom: 20px;
    background: #fff;

    >.top {
        background: #374263;
        color: #fff;
        padding: 10px 20px;
    }

    >.content {
        display: flex;

        >.section {
            width: 100%;
            padding: 15px 20px;
            border-right: var(--border-primary);
            display: flex;
            flex-direction: column;
            gap: 15px;
            color: #000D33;

            &:last-child {
                border-right: none;
            }

            >.section-value {
                >.title {
                    color: #818094;
                }

                >.value {
                    color: #000D33;
                }
            }
        }
    }
}