.alerts {
    position: relative;

    >.top {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-bottom: 40px;
    }

    >.content {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    >.report-alerts {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 0px;
        background: #EFEFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
}