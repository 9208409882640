.cashflow-report-row {
    text-align: end;

    &.result-row {
        color: #fff;
        background-color: #374263;
    }

    >td {
        padding: 10px;
        border-bottom: var(--border-primary);

        &.title {
            text-align: start;
        }
    }

    &:last-child>td {
        border-bottom: none;
    }

}