.account-search {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 360px;
    overflow-y: auto;

    &--account {
        font-weight: 500;
        font-size: 12px;
        color: #030229;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 15px;

    }
}