@import '../../styles/circle.scss';

.accounting-firm-groups {

    display: flex;
    flex-direction: column;
    gap: 30px;

    >.top {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        border-top: var(--border-primary);


        >.actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 15px;
            margin-left: auto;

            >span {
                color: #818094;
            }

            >.group-actions {
                @include circle(40px);
                background: #030229;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    >.empty-list {
        text-align: center;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 600px;
        border: 1px solid #efefff;

        .title {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            width: 90%;
            max-width: 350px;
        }

        .sub-title {
            font-weight: 400;
            font-size: 13px;
            width: 90%;
            max-width: 350px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }
}