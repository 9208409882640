.initial-setup {

  &--title {
    font-weight: 500;
    font-size: 40px;
    margin: 0;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid #EFEFFF;
  }

  &--form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
    width: 100%;

    &__terms {
      display: flex;
      font-size: 12px;

      p {
        margin-left: 5px;
      }

      a {
        margin-left: 3px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--inactive {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    text-align: center;
  }
}