@import "../../styles/circle.scss";

.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    >.inputs-wrapper {
        >label {
            @include circle(130px);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #F7F7F8;

            &:hover {
                >.camera-icon {
                    opacity: 1 !important;
                }
            }

            >.camera-icon {
                background: #ffffffcc;
                width: 40px;
                aspect-ratio: 1;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 27px;
                opacity: 0;
                transition: opacity 0.1s ease-in-out;
                color: #030229;
            }


        }
    }
}