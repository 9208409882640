.circle-chart {
    width: 105px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    >span {
        position: absolute;
        transition: opacity 100ms ease-in-out;
    }

    >.amount {
        opacity: 0;
    }


    &:hover {
        >.amount {
            opacity: 1;
        }

        >.procent {
            opacity: 0;
        }
    }
}

.circular-chart {
    display: block;
}

.circle-bg {
    fill: none;
    stroke-width: 2;
}

.circle-chart-inner {
    fill: none;
    stroke-width: 2;
    stroke-linecap: flat;
    animation: progress 1000ms ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}