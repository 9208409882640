@import "../../styles/circle.scss";

.message-list-item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 15px;
    border-bottom: var(--border-primary);

    &:hover {
        background: #37426333;
    }

    &:last-child {
        border-bottom: none;
    }

    >.unread {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #5B93FF;
        @include circle(10px);
    }


    >img {
        @include circle(50px);
    }

    >.content {
        display: flex;
        flex-direction: column;
        gap: 2px;

        >.bottom {
            color: #818094;
        }
    }
}