.variable-information {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    >.type {
        font-weight: 400;
        font-size: 12px;
        color: #818094;
        margin-bottom: 20px;
    }

    >.name {
        font-weight: 700;
        font-size: 18px;
        color: #030229;
        margin-bottom: 10px;
    }

    >.description {
        font-weight: 400;
        font-size: 13px;
        color: #444444;
        margin-bottom: 20px;
    }

    >.formula-title {
        font-weight: 400;
        font-size: 12px;
        color: #818094;
        margin-bottom: 10px;
    }
}