.results-report {
    width: 100%;
    margin-top: 20px;

    >.top {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        >.title {
            margin-right: 50px;
        }

        >.legend-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;

            &:last-child {
                margin-left: auto;
            }
        }
    }

    >.report-wrapper {
        max-width: 100%;
        overflow: auto;

        >table {
            border-radius: 10px;
            width: 100%;
            overflow: hidden;
            border-spacing: 0;
            background: var(--card-bg);
            border: var(--border-primary);

            >thead {
                background: #374263;
                color: #fff;

                >tr {
                    >th {
                        min-width: 100px;
                        text-align: end;
                        padding: 8px;
                        border-left: 1px solid #68677F;

                        &:first-child {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }
}