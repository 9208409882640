.create-accounting-firm-popup {
    >form {
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        width: 100%;

        >.profile-title {
            padding: 12.5px 20px;
            border-top: var(--border-primary);
        }

        >.profile-section {
            display: flex;
            align-items: center;
            padding: 12.5px 20px;
            gap: 15px;

            >span {
                margin-right: auto;
            }
        }

        >.profile-checkboxes {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 12.5px 20px;
            border-bottom: var(--border-primary);
        }

        >.profile-save-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 65px;
            margin-bottom: 30px;
            padding-right: 20px;
        }
    }
}