.tags-selector-wrapper {
    position: relative;

    >.tags-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        outline: none;
        background: #374263;
        border: 1px solid #A6A6AF;
        border-radius: 40px;
        color: #A6A6AF;
        padding: 5px 15px;
        cursor: pointer;
        text-align: start;


        >span {
            max-width: 170px;
            min-width: 170px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    >.tag-selector {
        position: absolute;
        top: 60px;
        left: 0;
        background: var(--card-bg);
        border: var(--border-primary);
        border-radius: 10px;
        width: 390px;
        z-index: 1000;

        >.title {
            color: #818094;
            padding: 20px 20px 10px 20px;
        }

        >.tags {}

        >form {
            border-top: var(--border-primary);
            padding: 10px 20px;

            >.title {
                color: #818094;
                margin-bottom: 15px;
            }

            >div {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
}