    .forgot-password-title {
        font-weight: 500;
        font-size: 40px;
        margin: 0;
        padding-bottom: 20px;
        margin-bottom: 30px;
        width: 100%;
        border-bottom: var(--border-primary);
    }

    .forgot-password-form {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 20px;
        width: 100%;
    }