@import '../../styles/circle.scss';

.popup-wrapper {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    align-items: flex-start;
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: center;
    overflow: auto;

    >.popup {
        background-color: var(--card-bg);
        border-radius: 20px;
        position: relative;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.1);
        width: 65vw;
        max-width: 950px;

        >.top {
            background: #374263;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            border-radius: 20px 20px 0 0;

            >.close-button {
                @include circle(30px);
                background: #030229;
                color: #fff;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
            }
        }
    }
}