@import "../../styles/variables.scss";

.login-background {
    grid-area: background;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: $small-breakpoint) {
    .login-background {
        display: none;
    }
}