.result-report-subrow-title {
    >td {
        padding: 7px;

        >.title-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            >.hybrid-sum {
                margin-left: auto;
            }
        }
    }
}