@import "../../../styles/circle.scss";

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  &.light {
    color: #a6a6af;

    &.checked {
      color: #fff;
    }
  }

  &.dark {
    color: #030229;
  }

  > .checkbox {
    @include circle(30px);
    display: flex;
    align-items: center;
    justify-content: center;

    &.short {
      @include circle(14px);
    }

    > .circle {
      opacity: 0;
      @include circle(50%);
    }

    &.light {
      border: 1px solid #818094;

      > .circle {
        background: #818094;
      }

      &.checked {
        border: 1px solid #fff;

        > .circle {
          opacity: 1;
          background: #fff;
        }
      }
    }

    &.dark {
      border: 1px solid #818094;

      &.checked {
        border: 1px solid #030229;

        > .circle {
          opacity: 1;
          background: #030229;
        }
      }
    }
  }
}
