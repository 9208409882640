@import "../../styles/circle.scss";

.legend-text {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 20px;

    >.dot {
        @include circle(8px);
    }
}