.cashflow-report {
    width: 100%;
    margin-top: 20px;

    >.top {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    >table {
        border-radius: 10px;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
        background: var(--card-bg);
        border: var(--border-primary);

        >thead {
            >tr {
                >th {
                    &.title {
                        width: 35%;
                    }

                    &.values {
                        width: 10%;
                    }

                    &.result {
                        width: 10%;
                    }
                }


            }
        }
    }
}