@import "../../../styles/circle.scss";

.dots-button {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    padding: 3px 0;

    >div {
        @include circle(5px);
        background: #818094;
    }
}