.login-content-title {
  font-weight: 500;
  font-size: 40px;
  margin: 0;
  padding-bottom: 20px;
  margin-bottom: 30px;
  width: 100%;
  border-bottom: var(--border-primary);
}

.login-content-oauth-buttons {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.login-content-divider {
  margin: 0;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  text-align: center;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    width: 50%;
    height: 1px;
    position: absolute;
    top: 51%;
    background-color: #efefff;
  }

  &:before {
    margin-left: -53%;
  }

  &:after {
    margin-left: 3%;
  }
}

.login-content-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
  width: 100%;
}

.login-content-forgot-password {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  width: 100%;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #5463ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.login-content-sign-up {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
  width: 100%;

  .sign-up-link {
    font-style: normal;
    color: #5463ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}