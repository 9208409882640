.footer {
    background-image: url("../../assets/images/footer-bg.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-bottom: 30px;

    >.top {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        border-bottom: 1px solid #1E3773;

        >span {
            color: #818094;
        }
    }

    >.content {
        display: flex;


        >section {
            display: flex;
            flex-direction: column;
            padding: 20px 30px;
            border-left: 1px solid #1E3773;
            width: 100%;
            gap: 10px;
            height: 250px;

            >p {
                color: #fff;
                margin-bottom: 10px;
            }

            >.link {
                color: #818094;

                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }

    >.bottom {
        border-top: 1px solid #1E3773;
        display: flex;
        gap: 20px;
        padding: 20px;

        >* {
            color: #818094;
        }

        >a {
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }

        >address {
            margin-right: auto;
            font-style: normal;
        }
    }
}