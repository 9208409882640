.accounting-firm-employees {
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 10px;

    >.title {
        padding: 20px;
        border-bottom: var(--border-primary);
    }

    >table {
        width: 100%;

        >thead {
            >tr {
                >th {
                    padding: 20px;
                    text-align: start;
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    padding: 12.5px 20px;

                    >.settings-dots {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                &.form-row {
                    >td {
                        border-top: var(--border-primary);
                    }
                }
            }
        }
    }
}