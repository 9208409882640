.early-access {
    padding: 0 5px;
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: var(--border-primary);
    ;
}