.variable-list-item {
    padding: 10px 15px;
    cursor: pointer;

    &.selected {
        background: #EFEFFF;
    }

    i {
        margin-right: 5px;
        color: #818094;
    }

    &:hover {
        background: #EFEFFF;
    }
}