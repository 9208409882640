.message-edit {
    >form {
        >input {
            width: 100%;
            padding: 15px 20px;
            outline: none;
            border: none;
            color: #000D33;
        }

        >textarea {
            width: 100%;
            height: 390px;
            resize: none;
            padding: 5px 20px;
            outline: none;
            border: none;
            color: #444;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px 0 20px;
            border-top: var(--border-primary);

            >.dropdowns {
                display: flex;
                gap: 15px;
            }
        }
    }
}