.company-settings-title {
    >.company-name {
        color: #fff;
    }

    >.company-number {
        color: #818094;
    }
}

.company-settings-popup {
    display: flex;
    min-height: 600px;
    position: relative;

    >.navigation {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        border-right: var(--border-primary);

        >.nav-item {
            padding: 10px 15px;
            text-align: start;
            background: #fff;
            border: none;
            cursor: pointer;
            border-bottom: var(--border-primary);

            &.active {
                background: #EFEFFF;
            }

            &:hover {
                background: #EFEFFF;
            }
        }
    }

    >.content {
        width: 100%;
        display: none;
        flex-direction: column;

        &.active {
            display: flex;
        }

        >.company-settings-row {
            border-bottom: var(--border-primary);
            padding: 20px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            >.checkboxes {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100px;
            }

            >.accounting-firm {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        >.save-settings {
            display: flex;
            justify-content: center;
            padding: 20px 0;
            margin-top: auto;
        }
    }
}