@import "../../styles/circle.scss";

.messages-wrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10000;
    width: 500px;
    gap: 10px;

    >.messages {
        width: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background: var(--card-bg);

        >.top {
            height: 60px;
            padding: 0 15px;
            background: #374263;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >.buttons {
                display: flex;
                align-items: center;
                gap: 15px;

                >.messages-button {
                    @include circle(30px);
                    background: #000D33;
                    border: 1px solid #000D33;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                }

                >.message-checkbox-wrapper {
                    position: relative;
                }
            }
        }

        >.content {
            height: 500px;
            min-height: 500px;
            overflow: auto;

            .showing-all {
                text-align: center;
                padding: 5px;
                color: #818094;
            }
        }
    }

    >.toggle-wrapper {
        overflow: visible;
        position: relative;

        >.toggle {
            @include circle(50px);
            background: var(--card-bg);
            border: var(--border-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;

            >i {
                animation: scaleClosed 200ms;
            }

            &.open {
                >i {
                    animation: scaleOpen 200ms;
                }
            }
        }
    }
}

@keyframes scaleOpen {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes scaleClosed {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}