.overview {
    >.controls {
        display: flex;
        margin-bottom: 30px;

        >.dropdowns {
            display: flex;
            gap: 20px;
            margin-left: auto;
            align-items: center;
        }
    }
}