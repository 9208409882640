.nimya-alert-wrapper {
    padding: 50px;
    display: flex;
    flex-direction: column;

    >.title {
        text-align: center;
        margin-bottom: 10px;
    }

    >.message {
        text-align: center;
    }

    >.alert-input {
        margin-top: 15px;

        >.error-message {
            color: red;
            text-align: center;
            margin-bottom: 5px;

        }

    }

    >.buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}