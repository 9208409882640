@import '../../../styles/circle.scss';

$switch-width: 60px;
$dot-size: 24px;
$animation-speed: 200ms;

.switch {
    position: relative;
    display: inline-block;
    width: $switch-width;
    height: 30px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            +.slider {
                background-color: rgba(84, 99, 255, 0.05);
                border: 1px solid #818094;


                &:before {
                    background-color: #374263;
                    -webkit-transform: translateX(calc($switch-width - $dot-size - 6px));
                    -ms-transform: translateX(calc($switch-width - $dot-size - 6px));
                    transform: translateX(calc($switch-width - $dot-size - 6px));
                }
            }
        }
    }

    >.slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 34px;
        background-color: #fff;
        border: 1px solid #efefff;
        -webkit-transition: $animation-speed;
        transition: $animation-speed;

        &:before {
            position: absolute;
            content: "";
            @include circle($dot-size);
            left: 2px;
            bottom: 2px;
            background: #818094;
            -webkit-transition: $animation-speed;
            transition: $animation-speed;
        }

    }
}