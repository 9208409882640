.budget-sheet-row-title {
    >td {
        padding: 7px;

        >.content {
            display: flex;
            align-items: center;
            gap: 10px;

            >i {
                cursor: pointer;
                font-size: 14px;
            }

            >input {
                margin-right: auto;
                outline: none;
                background: transparent;
                padding: 2px 0;
                border: none;
                cursor: pointer;
                margin-right: auto;
                width: 40%;


                &:focus {
                    cursor: text;
                }

                &:disabled {
                    cursor: text;
                    color: #2B2B2B;
                }
            }
        }
    }
}

.budget-sheet-row {}