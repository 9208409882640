.key-values-row-title {
    >td {
        padding: 5px 20px;

        >div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .key-values-row-popup {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 0 0 20px 20px;
                overflow: hidden;

                >.row-button {
                    text-align: start;
                    background: #fff;
                    border: none;
                    padding: 12px 20px;
                    width: 100%;
                    cursor: pointer;

                    &:hover {
                        background: #EFEFFF;
                    }
                }
            }
        }
    }
}