@import "../../styles/circle.scss";

.future {
    >.controls {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        >.budget-top {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            >.budget-action {
                @include circle(40px);
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}