@import "../../styles/circle.scss";

.extended-overview-row {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: var(--border-primary);
    margin-bottom: 20px;
    background: #fff;

    >.top {
        background: #374263;
        color: #fff;
        padding: 10px 20px;
    }

    >.content {

        >.section-row {
            width: 100%;
            border-bottom: var(--border-primary);
            display: flex;

            &:last-child {
                border-bottom: none;
            }

            >.section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-right: var(--border-primary);
                padding: 30px 20px;
                color: #000D33;

                &:last-child {
                    border-right: none;
                }

                >.values {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    >.value {
                        >.title {
                            color: #818094;
                        }
                    }
                }

                >img {
                    @include circle(105px);
                }
            }
        }
    }
}