.tag {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        background: #EFEFFF;

        >i {
            display: block;
        }
    }


    >span {
        margin-right: auto;
    }

    >i {
        display: none;
    }


}