.message-view {
    padding: 15px 0;
    display: flex;
    flex-direction: column;

    >.title {
        padding: 0 20px;
        margin-bottom: 15px;
    }

    >.content {
        height: 390px;
        white-space: pre-line;
        overflow-y: scroll;
        padding: 0 20px;
        color: #818094;
    }

    >.bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 0 20px;
        border-top: var(--border-primary);

        >span {
            color: #818094;
        }
    }
}