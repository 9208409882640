.budget-sheet {
    margin-bottom: 50px;

    >.top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 30px;

        >span {
            margin-right: auto;
        }
    }

    >table {
        border-radius: 10px;
        width: 100%;
        border-spacing: 0;
        overflow: hidden;
        background: var(--card-bg);
        border: var(--border-primary);

        >thead {
            background: #374263;
            color: #fff;

            >tr {
                >th {
                    min-width: 100px;
                    text-align: end;
                    padding: 8px 10px;
                    border-left: 1px solid #68677F;

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    >.bottom {
                        >.header {
                            color: #fff;
                            background: #374263;
                            padding: 10px;
                            width: 100%;
                        }

                        >div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 12px 20px;

                            >form {
                                display: flex;
                                align-items: center;
                                gap: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}