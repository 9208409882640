.key-values {
  display: flex;
  flex-direction: column;
  gap: 60px;

  > .controls {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    > .dropdowns {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
