.variable-value {

    &--account {
        background: #FAFAFB;
        border: 1px solid #EFEFFF;
        border-radius: 40px;
        width: 125px;
        padding: 10px 15px;
        font-weight: 500;
        font-size: 12px;
        color: #030229;
        text-align: center;
    }
}

.variable-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.variable-edit-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    &--section {
        gap: 15px;
        display: flex;
        align-items: flex-end;
    }
}