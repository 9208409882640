.button {
  text-align: center;
  padding: 0 15px;
  border-radius: 60px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-primary);
  white-space: nowrap;

  &.white {
    background: #fff;
    color: #000D33;
  }

  &.black {
    background: #030229;
    color: #fff;
    border: none;
  }

  &.red {
    background: #FF0000;
    color: #fff;
    border: none;
  }

  &.loading {
    cursor: wait;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.icon-button {
    .icon-button-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}