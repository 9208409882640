.group-settings-row {
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 10px;

    >.title {
        padding: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;

        >i {
            margin-left: auto;
        }
    }

    >.group-company-settings {
        display: flex;
        flex-direction: column;
        border-top: var(--border-primary);

        >table {
            width: 100%;

            >thead {
                >tr {
                    >th {
                        text-align: start;
                        padding: 20px;
                        border-bottom: var(--border-primary);
                    }
                }
            }

            >tbody {
                >tr {
                    >td {
                        padding: 20px;
                        border-bottom: var(--border-primary);
                    }

                    &:last-child {
                        >td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}