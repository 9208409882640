.voucher-wrapper {
    background: #fff;
    border: var(--border-primary);
    border-radius: 15px;

    .voucher {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        background: #fff;
        cursor: pointer;
        padding: 20px;
        border-radius: 15px;


        >.serie {
            font-weight: 500;
            font-size: 14px;
        }

        >.description {
            font-weight: 400;
            font-size: 14px;
        }

        >.date {
            color: #818094;
            margin-left: auto;
            font-weight: 500;
            font-size: 14px;
        }

        >.sum {
            font-weight: 500;
            font-size: 14px;
        }

        >.arrow {

            i {
                transition: transform 0.3s ease;
            }
        }
    }

    .voucher-rows {
        overflow: hidden;

        &-table {
            border-collapse: collapse;
            min-width: 400px;
            width: 100%;
            background: #fff;
            border-radius: 15px;

            thead {
                font-weight: 500;
                font-size: 13px;

                tr {
                    border-bottom: var(--border-primary);

                    td {
                        padding: 15px 20px;
                        min-width: 20px;
                        z-index: 2;
                        text-align: end;

                        &.account {
                            text-align: start !important;
                        }
                    }
                }
            }

            tbody {
                tr:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}