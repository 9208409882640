.statement-list {
    width: 300px;
    min-height: 650px;
    max-height: 650px;
    overflow: auto;
    border-right: 1px solid #efefff;

    &--section {
        border-bottom: 1px solid #efefff;

        .title {
            font-weight: 400;
            font-size: 12px;
            color: #818094;
            padding: 10px 15px;
        }
    }
}