.group-info {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    >.textarea-label {
        >textarea {
            width: 100% !important;
            height: 300px !important;
            outline: none;
            border: 1px solid #A6A6AF;
            color: #000D33;
            resize: none;
            border-radius: 10px;
            background: var(--card-bg);
            padding: 10px;

            &::placeholder {
                color: #818094;
                opacity: 0.7;
            }
        }
    }

    >.group-info-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
}