.vouchers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;

    .voucher-list-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    >.counter {
        position: fixed;
        bottom: 20px;
        left: 30px;
        z-index: 1;
        background-color: #fff;
        padding: 10px;
        border: 1px solid #efefff;
        border-radius: 10px;
        opacity: 0.5;
        transition: opacity 100ms ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
}