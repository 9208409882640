.payment-sheet-row-title {
    >td {
        >.title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 10px;
        }
    }
}

.payment-sheet-row {}