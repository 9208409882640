.text-button {
    border: none;
    background: transparent;
    cursor: pointer;

    >i {
        margin-left: 5px;
    }

    &:hover {
        text-decoration: underline;
    }

    &.loading {
        text-decoration: none;
    }

    &.disabled {
        opacity: 0.5;
    }
}