@import '../../styles/circle.scss';

.accounting-firm-row {
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 10px;


    >.row {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 20px;
        cursor: pointer;

        >.created {
            margin-left: auto;
        }
    }

    >.accounting-firm-info {
        >div {
            >.accounting-firm-actions {
                padding: 10px 20px;
                display: flex;
                align-items: center;

                >span {
                    margin-right: auto;
                }

                >.confirm-button {
                    @include circle(30px);
                    border: 1px solid #31BD00;
                    background: #f5fcf2;
                    color: #31BD00;
                    margin-right: 10px;
                }

                >.deny-button {
                    @include circle(30px);
                    border: 1px solid #FF0000;
                    background: #fff2f2;
                    color: #FF0000;
                }
            }
        }
    }
}