@import "../../styles/circle.scss";

.dot-wrapper {
    position: relative;
    width: 30px;

    >.dot {
        @include circle(30px);
        border: var(--border-primary);
        background: var(--card-bg);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #818094;

        &.active {
            background: #030229;
            color: #fff;
            border: none;
        }
    }

    >.lock {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        @include circle(12px);
        background: var(--card-bg);
        font-size: 8px;
        color: #31BD00;
        z-index: 1;
    }
}