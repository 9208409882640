.figure-card {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: var(--border-primary);
    background: var(--card-bg);

    >.top {
        background: var(--primary);
        padding: 12px;

        >span {
            color: var(--text-light);
        }
    }

    >.content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        margin-bottom: -1px;

        >.item {
            display: flex;
            min-height: 60px;
            border-bottom: var(--border-primary);
            flex-grow: 2;

            >.title {
                width: 40%;
                min-width: 40%;
                color: var(--text-primary);
                border-right: var(--border-primary);
                display: flex;
                align-items: center;
                padding-left: 20px;
            }

            >.value {
                color: var(--text-primary);
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 20px;
            }
        }
    }
}