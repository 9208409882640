@import "../../styles/variables.scss";

.app-wrapper {

    >.non-auth-top {
        grid-area: top;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 25px 0;
        border-bottom: var(--border-primary);
        margin-bottom: 30px;
    }
}

@media screen and (max-width: $small-breakpoint) {
    .app-wrapper {

        &.non-auth {
            min-width: unset;

            .dev-banner-wrapper {
                display: none;
            }
        }
    }
}