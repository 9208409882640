@import "../../styles/variables.scss";

.app-wrapper {
    position: relative;
    min-width: 1320px;
    max-width: 1920px;
    margin: auto;
    padding: 0 30px;

    >.content {
        padding-top: 15px;
        padding-bottom: 50px;
    }
}

@media screen and (max-width: $small-breakpoint) {
    .app-wrapper {
        padding: 0 10px;
    }
}