.verify-page {

    &--title {
        font-weight: 500;
        font-size: 40px;
        margin: 0;
        padding-bottom: 20px;
        margin-bottom: 30px;
        width: 100%;
        border-bottom: 1px solid #EFEFFF;
    }

    &--subtitle {
        font-weight: 400;
        font-size: 16px;
        color: #616078;
    }
}