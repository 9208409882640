.user-row {
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 10px;


    >.row {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 20px;
        cursor: pointer;

        >i {
            margin-left: auto;
        }
    }
}