.alert-row {
    border-radius: 10px;
    border: var(--border-primary);


    >.title {
        background: #374263;
        border-bottom: var(--border-primary);
        padding: 7px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        border-radius: 10px 10px 0 0;

        >i {
            color: #818094;
            margin-right: 7px;
        }
    }

    >.content {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 13px 20px;
        background: var(--card-bg);
        border-radius: 0 0 10px 10px;

        >.description {
            margin-right: auto;
        }
    }
}