@import "../../styles/circle.scss";

.navbar {
    position: sticky;
    top: 15px;
    z-index: 100;
    display: flex;
    align-items: center;
    background: var(--card-bg);
    border: var(--border-primary);
    border-radius: 40px;
    padding: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);


    >.company-name {
        color: #030229;
    }

    >.nav-items {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;

        >.nav-item {
            color: #818094;

            &.nav-item-settings {
                display: flex;
                align-items: center;
                justify-content: center;
                @include circle(40px);
                border: var(--border-primary);

                &.active {
                    background: #030229;
                    color: #fff;
                }
            }

            &.active {
                color: #030229;
            }
        }
    }
}