.group-users {
    >.title {
        padding: 20px;
        border-bottom: var(--border-primary)
    }

    >.group-member {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: var(--border-primary);

        >span {
            margin-right: auto;
        }
    }

    >.new-member {
        padding: 12.5px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    >.group-users-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
}