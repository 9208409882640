@import "../../../styles/circle.scss";

.key-value-arithmetic {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #030229;
    @include circle(30px);
    color: #fff;
    font-size: 14px;

    &:hover {
        background: #374263;
    }
}