.custom-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    >.accounts {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 0;
        overflow: auto;
        max-height: 525px;
        width: 100%;
    }

    >.bottom {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;
        padding: 20px 15px;
        border-top: var(--border-primary);

        >form {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}