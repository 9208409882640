.result-report-row-title {
    >td {
        padding: 7px;

        >.title-wrapper {
            display: flex;
            align-items: center;

            >i {
                margin-right: 10px;
            }

            >.hybrid-sum {
                margin-left: auto;
            }
        }
    }
}