.custom-key-values-popup {
    overflow: hidden;

    >.header {
        background: #374263;
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 10px 15px;

        >:last-child {
            margin-left: auto;
        }

        >.your-formula {
            color: #fff;
            text-align: start;
            margin-left: 0;
        }
    }

    >.formula {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-bottom: var(--border-primary);

        >.arithmetics {
            display: flex;
            align-items: center;
            gap: 10px;


            >span {
                color: #818094;
                white-space: nowrap;
            }
        }
    }

    >.content {
        display: flex;
    }
}