.budget-sheet-cell {
    border-right: var(--border-primary);
    padding: 7px;
    max-width: 40px;

    &.in-the-past {
        opacity: 0.5;
    }

    &:last-child {
        border-right: none;
    }

    >input {
        outline: none;
        background: #ffffff;
        text-align: end;
        border: none;
        cursor: pointer;
        padding: 0;
        width: 100%;

        &:focus {
            border: 1px solid #efefff;
            cursor: text;
        }

        &:disabled {
            color: #030229;
            cursor: text;
        }
    }

    >p {
        text-align: end;
        color: #818094;
    }
}